import axios from 'axios';
// OPEN CLOSE FORM
const form = {
	init: () => {
		let sendBtn = document.querySelectorAll('.js-send-message')
		sendBtn.forEach(i => {
			i.addEventListener('click', (e) => {
				e.preventDefault()
				
				let formToSubmit = e.currentTarget.closest('form')
				let data = new FormData(formToSubmit)
				
				let valid = form.validate(formToSubmit)
				if(valid){
					e.currentTarget.classList.add('disabled')
					form.send_message(formToSubmit)
				} else {

				}
			})
		})
	},
	send_message: (formToSubmit) => {
		let data = new FormData(formToSubmit)

		
		axios({
		 	url: formToSubmit.action,
		  	method: formToSubmit.method,
		  	headers: {
		    	'Accept': 'application/json'
		  	},
		  	data: data
		}).then((response) => {
			if (response.status == 200){
				document.querySelector('.js-popup.is-active').classList.remove('is-active')
				document.querySelector('html, body').classList.remove('noscroll')
				document.querySelector('.mini-alert.success').classList.add('is-active')
				document.querySelectorAll('input, textarea, select').forEach(i => i.value = '')
				
				let param = document.querySelector('#form-student').getAttribute('data-page') || null
				if (param){
					window.location.href = '/message-sent/' + param
				} else {
					window.location.href = '/message-sent'
				}
				
			} else {
				document.querySelector('.mini-alert.error').classList.add('is-active')
			}
			document.addEventListener('click', () => {
				// close mini alert on click
				document.querySelector('.mini-alert.is-active').classList.remove('is-active')
			})
			document.querySelectorAll('form button').forEach(i => i.classList.remove('disabled'))
		 })	
	}, 
	validate: (formToSubmit) => {
		let data = new FormData(formToSubmit)
		let valid = true
		let inputs = formToSubmit.querySelectorAll('input, textarea, select')
		let privacy = formToSubmit.querySelector(`[name="privacy"]`)

		let customerType = formToSubmit.querySelector("[name='customer_type']")
		inputs.forEach(i => {
			i.classList.remove('not-valid')
		})

		const errorMsg = formToSubmit.querySelector('.messages-list')

		for (const value of data.entries()) {

		  if (value[1] == '' || value[1] == null){
		  	valid = false
		  	formToSubmit.querySelector(`[name="${value[0]}"]`).classList.add('not-valid')
		  	let errorDiv = formToSubmit.querySelector(`[name="${value[0]}"]`).nextElementSibling
		  	errorDiv.innerHTML = ''
		  	errorDiv.innerHTML = errorMsg.getAttribute('data-blank')
		  }
		  if (value[0] == 'phone' && form.validatePhone(value[1]) == false && value[1] != ''){
			valid = false
		  	formToSubmit.querySelector(`[name="${value[0]}"]`).classList.add('not-valid')
		  	let errorDiv = formToSubmit.querySelector(`[name="${value[0]}"]`).nextElementSibling
		  	errorDiv.innerHTML = ''
		  	errorDiv.innerHTML = errorMsg.getAttribute('data-phone')
		  }


		  if (value[0] == 'email' && form.validateEmail(value[1]) == false && value[1] != ''){
			valid = false
		  	formToSubmit.querySelector(`[name="${value[0]}"]`).classList.add('not-valid')
		  	let errorDiv = formToSubmit.querySelector(`[name="${value[0]}"]`).nextElementSibling
		  	errorDiv.innerHTML = ''
		  	errorDiv.innerHTML = errorMsg.getAttribute('data-email')
		  }

		}
		if (!privacy.checked ){
			privacy.classList.add('not-valid')
			let errorDiv = privacy.nextElementSibling
		  	errorDiv.innerHTML = ''
		  	errorDiv.innerHTML = errorMsg.getAttribute('data-privacy')
		}

		if (valid == true){
			valid = privacy.checked
		}

		if (formToSubmit.id == 'student' && customerType.value == ''){
			customerType.classList.add('not-valid')
			let errorDiv = privacy.nextElementSibling
		  	errorDiv.innerHTML = ''
		  	errorDiv.innerHTML = errorMsg.getAttribute('data-blank')
		 	valid=false
		}

		return valid
	},

	validatePhone: (phone) => {
	  	var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	  	return re.test(phone);
	},

	validateEmail: (email) => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  	return re.test(email);
	}
}


document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('#form-student')){
		form.init()
		form.send_message
	}
})