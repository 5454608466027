document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelectorAll('.faq_item_question')){
        document.querySelectorAll('.faq_item_question').forEach(i => {
            i.addEventListener('click',  ()=> {
                let id = i.getAttribute('data-id')
                let closeItem = false
                if(i.classList.contains('is-active')){
                    closeItem = true
                }
                document.querySelectorAll('.faq_item_answer, .faq_item_question').forEach(ii => {
                    ii.classList.remove('is-active')
                }) 
                
                if(closeItem == false){
                    i.classList.add('is-active')
                    document.querySelector(`[data-faq='${id}']`).classList.add('is-active')
                }
            })
        })
    }
})