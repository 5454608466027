
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollToPlugin} from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
const myPlugins = [CSSPlugin]
gsap.registerPlugin(ScrollTrigger); 
gsap.registerPlugin(ScrollToPlugin); 

function resizeTabs(tabs){
    tabs.forEach(i => {
        let contents = i.querySelectorAll('.tabs_content_item')
        let heights = []
        contents.forEach(c => {
            heights.push(c.offsetHeight)
        })
        let max = Math.max(...heights)
        i.querySelector('.tabs_content').style = `height: ${max + 80}px`
    })
}

function tabSelector(){
    let buttons = document.querySelectorAll('.js-open-tab')
    buttons.forEach( btn => {
        btn.addEventListener('click',  (evt) => {
            let tabId = evt.target.getAttribute('data-tab')
            let contentId = evt.target.getAttribute('data-content')
            let tab = document.querySelector(`#${tabId}`)
            tab.querySelectorAll('.js-open-tab').forEach(i => i.classList.remove('is-active'))
            evt.target.classList.add('is-active')
            tab.querySelectorAll('.tabs_content_item').forEach(tabcontent => {tabcontent.classList.remove('is-active')})
            tab.querySelector(`#${contentId}`).classList.add('is-active')
        })
    })
}

function showIndex(){
    gsap.to(".tabs_index", {scrollTrigger:{
            trigger: ".tabs-div", 
            start: "top center",
            end: "top center",
            scrub: 1
        },
        duration: 0.1, 
        autoAlpha: 1
    });
    gsap.to(".tabs_index", {scrollTrigger:{
        trigger: ".tabs-div", 
        start: "bottom center",
        end: "bottom center",
        scrub: 1
    },
    duration: 0.1, 
    autoAlpha: 0
});
    document.querySelectorAll('.tabs_index li').forEach(i => {
        let id = i.querySelector('a').getAttribute('data-scroll')
        gsap.to(i, {scrollTrigger:{
                trigger: `#${id}`, 
                start: "top center",
                end: "bottom center",
                onEnter:() => {
                    i.classList.add('is-active');
                  },
                  onLeave:() => {
                    i.classList.remove('is-active');
                  },
                  onEnterBack:() => {
                    i.classList.add('is-active');
                  },
                  onLeaveBack:() => {
                    i.classList.remove('is-active');
                  }
            }
        });

    })
}




document.addEventListener('DOMContentLoaded',  () => {
    let tabs = document.querySelectorAll('.tabs')

    if(tabs.length > 0){
        resizeTabs(tabs)
        window.addEventListener('resize', ()=>{
            resizeTabs(tabs)
        })
        tabSelector()
        showIndex()
    }
})