import $ from 'jquery';
import 'slick-carousel';


document.addEventListener('DOMContentLoaded',  () => {
    if($('.review_carousel')){
        $('.review_carousel').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true, 
            prevArrow: '<div class="review_carousel_prev"><img src="./icons/arrow-l.svg" class="fw-image" alt="arrow-l"></div>',
            nextArrow: '<div class="review_carousel_next"><img src="./icons/arrow-r.svg" class="fw-image" alt="arrow-r"></div>'
        })
    }


    if($('.page_reviews_slider')){
        $('.page_reviews_slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false, 
            prevArrow: '<div class="page_reviews_slider_prev"><img src="/icons/arrow-l-w.svg" class="fw-image" alt="arrow-l"></div>',
            nextArrow: '<div class="page_reviews_slider_next"><img src="/icons/arrow-r-w.svg" class="fw-image" alt="arrow-r"></div>'
        })
    }
})