require("@rails/ujs").start()
require("channels")

require('components/carousel')
require('components/faq')
require('components/form')
require('components/tabs')


import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import fadeInScroll from "../components/fade-in-scroll.js"
import { formEnableSelector } from '@rails/ujs'

const FADEINTIME = 1;
document.addEventListener('DOMContentLoaded', ()=> {
	gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )

	watchForHover()
  isSafari(); 
  toggleMenu(); 
  fadeInScroll();
  openForm();
  closePopup(); 
  formSelector();

  if(document.querySelector('.js-scroll-to')){
    scrollTo()
  }
})

function isSafari() {
  var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  if (isSafari) { 
    document.querySelector('body').classList.add('is-safari')
  };
}

function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return
    document.body.classList.add('hasHover')
  }

  function disableHover() {
    document.body.classList.remove('hasHover')
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date()
  }

  document.addEventListener('touchstart', updateLastTouchTime, true)
  document.addEventListener('touchstart', disableHover, true)
  document.addEventListener('mousemove', enableHover, true)

  enableHover()
}

function toggleMenu(){
  document.querySelector('.js-toggle-menu').addEventListener('click', () => {
    document.querySelector('.burger').classList.toggle('is-active')
    document.querySelector('.menu-mobile').classList.toggle('is-active')
    document.querySelector('.header').classList.toggle('menu-active')
    document.querySelector('body, html').classList.toggle('noscroll')
  })
}

function scrollTo(){

  let scrollToLinks = document.querySelectorAll('.js-scroll-to')
  scrollToLinks.forEach(i => {
    i.addEventListener('click', () => {
      let id = i.getAttribute('data-scroll')
      gsap.to(window, {duration: 0.4, scrollTo: {y:`#${id}`, offsetY: 50}});
    })
  })
}

function openForm(){
  var btns = document.querySelectorAll('.js-open-form')
  btns.forEach(i => {
    i.addEventListener('click', () => {
      document.querySelector('body, html').classList.add('noscroll')
      let id = i.getAttribute('data-form')
      document.querySelector(`#form-${id}`).classList.add('is-active')
    })
  })
}

function formSelector(){
  var btns = document.querySelectorAll('.js-form-selector')
  btns.forEach(i => {
    i.addEventListener('click', () => {
      let id = i.getAttribute('data-form')
      btns.forEach(ii => ii.classList.remove('is-active'))
      i.classList.add('is-active')
      document.querySelectorAll('form').forEach(ii => ii.classList.remove('is-active'))
      document.querySelector(`#${id}`).classList.add('is-active')
    })
  })
}

function closePopup(){
  var popupCloseBtns = document.querySelectorAll('.js-close-popup ')
  popupCloseBtns.forEach( i => {
    i.addEventListener('click',  () => {
      document.querySelector('body, html').classList.remove('noscroll')
      document.querySelectorAll('.js-popup').forEach( ii => {
        ii.classList.remove('is-active')
      })
    })
  })

  document.querySelector('.form-container').addEventListener('click', (e) => {
    // let clickedOutside = true;
    //     e.path.forEach(item => {
    //       if (!clickedOutside)
    //         return;

    //       if (item.className === 'form-container_inner')
    //         clickedOutside = false;
    //     });

      


    //   if (clickedOutside){
    //      document.querySelector('body, html').classList.remove('noscroll')
    //       document.querySelectorAll('.js-popup').forEach( ii => {
    //         ii.classList.remove('is-active')
    //       })
    //   }
  })
    
}